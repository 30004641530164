html,
body,
header,
#topImage {
  height: 100%;
}

#topImage img {
  margin-top: -52px;
}

.carousel-indicators li {
  text-indent: 0;
  font-family: monospace;
  width: auto;
  height: 17px;
  border: none;
  cursor: pointer;
  line-height: 17px;
  color: rgb(115, 108, 108);
  background-color: #fff;
  -webkit-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-clip: initial;
}

.carousel-indicators .active {
  background-color: #03afd9;
  color: white;
}

.compsLive {
  color: #03afd9;
}

.carousel-indicators .notActive {
  background-color: rgba(0, 0, 0, 0) !important;
}

.compsCounter {
  display: inline-block;
  padding: 0;
  width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 13px;
  color: white;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  background-color: #03afd9;
  border-radius: 50px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  position: relative;
  top: -1px;
}

.carouselNumbers,
.carouselNumbers2 {
  padding-left: 5px;
  font-size: 13px;
  padding-right: 5px;
}

.carousel-indicators {
  position: absolute;
  top: -30px;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: flex-end;
  padding-left: 0;
  margin-right: 2%;
  margin-left: 145px;
  list-style: none;
  height: 30px;
  align-items: center;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1;
}

.username {
  font-size: 1.3rem;
}

.table td,
.table th {
  padding: 0.4rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.btn-group-sm > .btn,
.btn-sm {
  /* padding: .25rem .5rem; */
  font-size: 0.875rem;
  /* line-height: 1.5; */
  border-radius: 0.2rem;
  padding-left: -11px;
  margin-left: -12px;
  /*height: 38px;*/
}

.slide-overlay {
  color: #ffffff;
  position: absolute;
  bottom: 42%;
  font-weight: bold;
  text-shadow: 2px 2px 2px #272727;
  right: 0;
  font-size: 1.5rem;
  width: 100%;
  text-align: center;
  line-height: 38px;
}

@media (max-width: 575px) {
  .slide-overlay {
    left: 0;
    width: 100%;
  }
}

.slide-overlay .bigger-text {
  font-size: 3rem;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  background: transparent;
}

.homeSubmit {
  height: 26px;
}

.theme-home-mileage {
  padding: 0px 24px 0px 8px !important;
  line-height: 20px !important;
  height: 22px !important;
}

.theme-home-mileage-btn {
  height: 22px !important;
  min-width: 60px;
  font-size: 12px !important;
  padding: 1px 10px !important;
}
@media (min-width: 992px) {
  .theme-home-mileage-container {
    max-width: 203px !important;
  }
}

.homeCH {
  font-size: 1rem;

  padding: 0rem 0.7rem !important;
  border-radius: 0px !important;
}
.homeCH > a {
  vertical-align: text-top;
}
.react-resizable-handle {
  display: none;
}

.home-promo-customise {
  padding: 0 30px;
}

.btn-home-promo-customise {
  color: #002c5f;
  padding-left: 0;
}

.promos-move {
  display: none;
}
.home-promo-customise-mode .promos-move {
  display: inline;
  margin: 6px 6px 6px 0px;
  float: left;
}
.react-draggable {
  cursor: move;
}

@media (max-width: 767px) {
  .theme-vehicle-img-container {
    text-align: center;
  }
}

.theme-home-card-footer {
  font-size: 15px;
}
