@media (min-width: 768px) and (max-width: 991px) {
  .theme-offerSideNav-title > h4 {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .theme-offerSideNav-title + br {
    display: none;
  }
}
