.theme-registration-bg-image {
  background-image: url('../images/coffeeImg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

#regleftCol {
  background: rgba(255, 255, 255, 0.8);
  color: rgb(58, 58, 58);
  padding: 1rem;
  height: 100%;
}

#regrightCol {
  background: rgba(255, 255, 255, 1);
  padding: 1rem;
}

@media (max-width: 991px) {
  .theme-registration-bg-image {
    background-image: none !important;
  }

  #regrightCol {
    background: rgb(255, 255, 255, 1);
    padding: 1rem;
    margin-left: 0px !important;
    width: 100%;
  }
}
@media (min-width: 992px) {
  #regleftCol {
    margin-top: 7rem;
  }
}

.regMargin {
  margin-bottom: -29px;
}
