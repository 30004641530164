.article-from-grid .article-image {
  /* grid */
  width: 100%;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  vertical-align: middle;
}

.article-from-login .article-image {
  /* login */
  width: 100%;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  vertical-align: middle;
}

.article-from-article .article-image {
  /* article */
  width: 100%;
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  vertical-align: middle;
}

.read-logo {
  width: 22px;
  height: 20px;
  background-image: url(../images/readlogo.png);
  background-size: cover;
  overflow: hidden;
}

.card-body.body-from-login {
  background-color: white;
  max-height: 75px;
  min-height: 75px;
}

.card-body.body-from-article {
  background-color: #f5f5f5;
  max-height: 75px;
  min-height: 75px;
}

.card-body.body-from-grid {
  background-color: #f5f5f5;
  max-height: 75px;
  min-height: 75px;
}

.card-text-div.text-from-login {
  background-color: white;
  max-height: 50px;
  min-height: 50px;
}

.card-text-div.text-from-article {
  background-color: #f5f5f5;
  max-height: 50px;
  min-height: 50px;
}

.card-text-div.text-from-grid {
  background-color: #f5f5f5;
  max-height: 50px;
  min-height: 50px;
}

.card p:hover {
  text-decoration: none;
}

@media (max-width: 991px) {
  .article-from-grid {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}
