@media (max-width: 770px) {
  #moreArticlesButton {
    max-width: 400px;
  }
}

#moreArticlesButton {
  min-width: 200px;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}
