@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}

.category {
  padding-left: 0;
}

.embeded {
  width: 100%;
}

@media (max-width: 601px) {
  .category {
    padding-left: 15px;
  }
}
