.fa-phone {
  transform: rotate(90deg);
}

.fa-wrench {
  /* transform: rotate(270deg); */
}

.username {
  font-size: 1rem;
  color: #002c5f;
}
.theme-nav {
  padding: 0.5rem 1rem;
}
@media (max-width: 400px) {
  .theme-nav {
    padding-left: 0;
    padding-right: 0;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}
