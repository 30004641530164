.content {
  background-color: rgb(255, 255, 255);
}

#leftCol {
  background: rgba(220, 215, 215, 0.9);
  color: rgb(59, 58, 58);
  padding: 1rem;
}
#rightCol {
  background: rgba(255, 255, 255, 1);
  padding: 1rem;
}

@media screen and (min-width: 1024px) {
  #leftCol {
    height: 100%;
    margin-top: 16rem;
  }
}

.tr {
  background-color: rgb(202, 199, 199);
}

.br {
  background-color: rgb(167, 167, 172);
}

.white {
  background-color: white;
  opacity: 0.95;
}

.infoContainer {
  background-color: rgb(238, 232, 232);
  margin-top: 28%;
  /* padding-bottom: 1rem; */
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  opacity: 0.93;
}

#topEditButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.btn-dark {
  color: #fff;
  background-color: #787f86;
  border-color: #788088;
}

#subHeadingTitle {
  background-color: rgb(236, 236, 236);
  padding: 0.5rem;
}

#firstrow {
  max-width: 300px !important;
}
