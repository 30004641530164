.theme-servicePlans-blurb {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translate(0, -50%);
  padding: 2rem;
  color: #fff;
  text-shadow: 0 0 15px;
}
.theme-servicePlans-blurb p {
  font-size: 1rem;
  color: white;
  text-shadow: 0.1em 0.1em 0.1em #002a54;
  font-weight: bold;
}

@media only screen and (max-width: 600px) {
  .theme-servicePlans-blurb p {
    font-size: 0.7rem;
    color: white;
    text-shadow: 0.1em 0.1em 0.1em #002a54;
    font-weight: bold;
    margin-bottom: 5px;
  }

  .servicePlans {
    margin-bottom: 6px;
  }
}
