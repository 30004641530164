.theme-postItemMultiple-item-border {
  border: 3px solid #dee2e6;
}
.theme-postItemMultiple-spacer {
  display: none;
}

@media (max-width: 575px) {
  .theme-postItemMultiple-closes {
    display: none;
  }
}
