.competition-body {
  padding-top: 20px;
}

.competition-body h3 {
  color: #002c5f;
  font-size: 1.4rem;
  font-weight: bold;
  padding-bottom: 10px;
}

.competition-body h4 {
  color: #002c5f;
  font-size: 1.3rem;
  font-weight: normal;
  padding-bottom: 10px;
}

.competition-body p {
  font-size: 1rem;
}

.phone-select {
  flex-basis: 40% !important;
}
.phone-input {
  flex-basis: 60% !important;
}

.terms-link,
.terms-link :hover,
.terms-link :active,
.terms-link :visited {
  color: black;

  text-decoration: underline;
  font-weight: bold;

  background-color: transparent;
  border: none;
  padding-left: 5px;
}

.terms-link-sup {
  font-weight: bold;
}
.terms-link-span {
  line-height: 24px;
}

.telephone-label {
  min-width: 150px;
}

.phone-options {
  min-width: 233px;
}

.phone-options label {
  display: inline !important;
  padding-left: 3px;
  padding-right: 15px;
}

.phone-options input {
  display: inline !important;
  max-width: 15px;
}
