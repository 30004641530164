/* .section1 {
  background-color: rgb(245, 245, 245);
  padding: 2.5rem 0rem !important;
} */

.content {
  background-color: rgb(255, 255, 255);
}

#leftCol {
  background: rgba(220, 215, 215, 0.9);
  color: rgb(59, 58, 58);
  padding: 1rem;
}
#rightCol {
  background: rgba(255, 255, 255, 1);
  padding: 1rem;
}

@media all and (max-width: 1020px) {
  .infoContainer {
    background-color: rgb(238, 232, 232);
    margin-top: 10% !important;
    /* padding-bottom: 1rem; */
    margin-bottom: 1.5rem !important;
    margin-left: 1rem !important;
    opacity: 0.93 !important;
  }
}

@media screen and (min-width: 1024px) {
  #leftCol {
    height: 100%;
    margin-top: 16rem;
  }
}

.tr {
  background-color: rgb(202, 199, 199);
}

.br {
  background-color: rgb(167, 167, 172);
}

.white {
  background-color: white;
  opacity: 0.95;
}

.infoContainer {
  background-color: rgb(238, 232, 232);
  margin-top: 28%;
  /* padding-bottom: 1rem; */
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  opacity: 0.93;
}

.theme-profile-header-img-container {
  padding-left: 15px;
  padding-right: 15px;
}

.theme-profile-header-img-container .image-edit-button {
  position: absolute;
  bottom: 0px;
  right: 10px;
}
.theme-profile-img-container .image-edit-button {
  position: absolute;
  bottom: 0px;
  right: 10px;
}
.theme-profile-img-container {
  margin-top: -165px;
  width: 166px;
  padding-left: 15px;
  position: relative;
}
.theme-personel-profile-width {
  max-width: 166px;
}

.theme-personal-image {
  width: 100%;
  height: 166px;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}

.dropzone {
  width: 100%;
  height: 40vh;
  border-width: 2px;
  border-color: #bbb;
  border-style: dashed;
  border-radius: 3px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropzone.drophover {
  -moz-box-shadow: inset 0px 3px 3px 0px #8c8c8c,
    inset 0px -8px 17px -7px #ffffff;
  -webkit-box-shadow: inset 0px 3px 3px 0px #8c8c8c,
    inset 0px -8px 17px -7px #ffffff;
  box-shadow: inset 0px 3px 3px 0px #8c8c8c, inset 0px -8px 17px -7px #ffffff;
  background-color: #efefef;
}
.dropzone.drophover .theme-personal-upload-btn {
  -moz-box-shadow: 0px 3px 3px 0px #8c8c8c;
  -webkit-box-shadow: 0px 3px 3px 0px #8c8c8c;
  box-shadow: 0px 3px 3px 0px #8c8c8c;
}

.dropzone.drophover .theme-personal-upload-text {
  text-shadow: 1px 1px #ffffff;
}

.theme-personal-upload-btn {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
.image-loading {
  position: relative;
  width: 100%;
  height: 166px;
  text-align: center;
  display: flex;
  background-color: rgba(0, 0, 0, 0.2);
}
.image-loading svg {
  margin: auto;
  color: white;
}

.btn-dark {
  color: #fff;
  background-color: #787f86;
  border-color: #788088;
}

#subHeadingTitle {
  background-color: rgb(236, 236, 236);
  padding: 0.5rem;
}

.personalUsername {
  position: absolute;
  bottom: 0;
  margin-bottom: 0px;
  background-color: rgba(255, 255, 255, 0.65);
  padding: 0px 5px;
  word-break: break-word;
}

@media (min-width: 1200px) {
  .personalUsername {
    max-width: 527px;
    left: 335px;
  }
}
@media (max-width: 1199px) {
  .personalUsername {
    max-width: 427px;
    left: 283px;
  }
}
@media (max-width: 991px) {
  .personalUsername {
    max-width: 292px;
    left: 216px;
  }
}
@media (max-width: 767px) {
  .personalUsername {
    max-width: 249px;
  }
}

.personalFirst {
  font-size: 1.7rem;
  color: #002c5f;
  font-weight: lighter;
}

.personalLast {
  font-size: 1.7rem;
  color: #002c5f;
  font-weight: bolder;
}

.persButtonLeft {
  margin-right: -10px;
}

.personalUpdateButton {
  margin-bottom: -65px;
}
.theme-personal-password-container {
  flex: 1 1 auto;
  width: 1%;
}
