@media (max-width: 768px) {
  .handbook-button-container,
  .handbook-name-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .handbook.col-sm-12 {
    margin-bottom: 3rem;
  }
  .font-large.handbook-font {
    font-size: large;
    padding-top: 0.5rem;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
  .font-medium.handbook-font {
    font-size: medium;
    padding-top: 0.5rem;
    padding-left: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
}

.handbook {
  margin-bottom: 2rem;
}

.handbook-name-container.handbook-multi {
  display: flex;
  align-items: center;
  justify-content: center;
}

.font-medium {
  font-size: medium;
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 0;
  margin-bottom: 0;
}

.font-large {
  font-size: large;
  padding-top: 1.2rem;
  padding-left: 1.5rem;
  padding-bottom: 0;
  margin-bottom: 0;
}
