.card img:hover {
  opacity: 0.9;
}

.card-title:hover {
  opacity: 0.9;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  #navbar,
  #nav-row {
    width: 100%;
  }
}
