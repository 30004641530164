.content {
  background-color: rgb(255, 255, 255);
}

#leftCol {
  background: rgba(220, 215, 215, 0.9);
  color: rgb(59, 58, 58);
  padding: 1rem;
}
#rightCol {
  background: rgba(255, 255, 255, 1);
  padding: 1rem;
}

@media all and (max-width: 1020px) {
  .infoContainer {
    background-color: rgb(238, 232, 232);
    margin-top: 10% !important;
    /* padding-bottom: 1rem; */
    margin-bottom: 1.5rem !important;
    margin-left: 1rem !important;
    opacity: 0.93 !important;
  }
}

@media screen and (min-width: 1024px) {
  #leftCol {
    height: 100%;
    margin-top: 16rem;
  }
}

.tr {
  background-color: rgb(202, 199, 199);
}

.br {
  background-color: rgb(167, 167, 172);
}

.white {
  background-color: white;
  opacity: 0.95;
}

.infoContainer {
  background-color: rgb(238, 232, 232);
  margin-top: 28%;
  /* padding-bottom: 1rem; */
  margin-bottom: 1.5rem;
  margin-left: 1rem;
  opacity: 0.93;
}

#topEditButton {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.btn-dark {
  color: #fff;
  background-color: #787f86;
  border-color: #788088;
}

#subHeadingTitle {
  background-color: rgb(236, 236, 236);
  padding: 0.5rem;
}

.rem {
  background-color: #f5f5f5;
}

.remh {
  background-color: #f5f5f5;
  color: #002c5f;
  border-bottom: 0;
  padding-bottom: 0;
}

.remb {
  width: 42px !important;
  height: 42px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1;
  border-radius: 22px;
  background-color: white;
  color: black;
  font-weight: bold;
  border-color: gray;
}

.rembSelected {
  width: 42px !important;
  height: 42px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1;
  border-radius: 22px;
  background-color: #00aad2;
  color: white;
  font-weight: bold;
  border-color: gray;
}
.rembSelected > small,
.remb > small {
  display: inline-block;
  line-height: normal;
  vertical-align: middle;
}

.remCol {
  padding-right: 0;
}

.remCardBody {
  padding: 1.25rem 1.7rem 0rem 1.25rem !important;
}

.remSaveButton {
  border-radius: 0px;
  margin-top: 2rem;
  width: 26%;
  margin-right: 4%;
}

@media (max-width: 576px) {
  .remSaveButton {
    border-radius: 0px;
    margin-top: 2rem;
    width: 33% !important;
    margin-right: 4%;
  }
}
