.footer {
  position: static;
  bottom: 0;
  width: 100%;
  line-height: 48px;
}

.clrBlack {
  background-color: rgb(19, 19, 19);
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;
}

#footerLogo {
  max-width: 9rem;
}

.socMedia a {
  padding-right: 0.2rem;
}
@media (max-width: 1199px) {
  .socMedia {
    text-align: center;
  }
}

@media (max-width: 770px) {
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}
