#hyundai-whn-nav-login {
  width: 140%;
}

#loginRegisterButtonNav:hover,
#loginActiveButtonNav:hover,
#dropdownMenuButtonMemberWHN:hover {
  background-color: whitesmoke;
}

#loginRegisterButtonNav,
#loginActiveButtonNav,
#dropdownMenuButtonMemberWHN {
  background-color: white;
}

#navbar {
  padding-left: 0;
  padding-right: 0;
}

#hyundai-whn-nav-member {
  width: 104%;
  padding: 1%;
}

#nav-row {
  flex-direction: row;
}

#nav-banner {
  margin-right: 10%;
}

#loginRegisterButtonNav {
  text-align: left;
  border: none;
}

#loginActiveButtonNav {
  text-align: left;
  border: none;
}

#whn-input-button {
  margin-top: 0;
}

#whn-input {
  margin-top: 0px;
  margin-right: 3px;
}

@media (max-width: 991px) {
  #hyundai-whn-nav-login {
    width: 100%;
    padding: 3%;
  }
  #hyundai-whn-nav-member {
    width: 100%;
  }

  #whn-input {
    padding-left: 0;
    margin-top: 3px;
  }

  #basic-addon1 {
    display: none;
  }

  #navbar {
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
  }

  #nav-banner {
    padding-top: 10px;
    padding-left: 0;
    margin-right: 0;
    order: 3;
  }

  #whn-input-button {
    margin-top: 2px;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}

@media screen and (min-width: 992px) and (max-width: 1199px) and (-ms-high-contrast: none),
  (-ms-high-contrast: active) {
  #dropdownMenuButtonMemberWHN {
    min-height: 50px;
  }
  #hyundai-whn-nav-login {
    min-width: 250px;
  }
}
