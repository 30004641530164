.competition-body {
  padding-top: 20px;
}

.competition-body h3 {
  color: #002c5f;
  font-size: 1.4rem;
  font-weight: bold;
  padding-bottom: 10px;
}

.competition-body h4 {
  color: #002c5f;
  font-size: 1.3rem;
  font-weight: normal;
  padding-bottom: 10px;
}

.competition-body p {
  font-size: 1rem;
}

.phone-select {
  flex-basis: 40% !important;
}
.phone-input {
  flex-basis: 60% !important;
}

.terms-link-sup {
  font-weight: bold;
}

.telephone-label {
  min-width: 150px;
}
.theme-competitions-phone-options {
  padding-top: 26px;
  padding-bottom: 25px;
}
.theme-competitions-phone-options label {
  line-height: 24px;
}
