.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}
.react-datepicker__close-icon {
  top: 16px;
  position: absolute;
  right: 0;
}

.react-datepicker__close-icon::after {
  background-color: #e9ecef;
  border-radius: 20%;
  bottom: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #858585;
  content: '\D7';
  cursor: pointer;
  font-size: 26px;
  height: 16px;
  width: 17px;
  line-height: 21px;
  margin: -9px auto 0;
  padding: 0px;
  position: absolute;
  right: 7px;
  text-align: center;
  top: 50%;
}
.rem .btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #b3b3b3;
  border-color: #a9a9a9;
}

.react-datepicker__month-container {
  float: none;
  z-index: 100000;
}

.react-datepicker {
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  /* background-color: #fff; */
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: relative;
  float: none;
  z-index: 1000000000;
}
