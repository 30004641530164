.theme-login-bg-image {
  background-image: url('../images/myHyundaiLogo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
}

.theme-login-bg-image-mobile {
  background-image: none;
  max-height: 0px;
}

.block {
  flex: 0 0 45%;
  max-width: 45%;
  margin-right: 2.5%;
  margin-left: 2.5%;
}

#loginEmailInput {
  background-color: transparent;
}

#loginPasswordInput {
  background-color: transparent;
}

#loginRegisterButton .login-page {
  text-align: center;
}

#loginActiveButton .login-page {
  text-align: center;
}

@media (max-width: 991px) {
  .theme-login-bg-image {
    background-image: none;
  }
  .theme-login-bg-image-mobile {
    background-image: url('../images/myHyundaiLogo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
  }

  .block {
    flex: 0 0 90%;
    max-width: 90%;
    margin-right: 5%;
    margin-left: 5%;
  }
}
.theme-login-registration-bg-color {
  background-color: rgb(202, 199, 199);
}

.theme-login-activation-bg-color {
  background-color: rgb(167, 167, 172);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
  .card a {
    height: 100%;
  }

  .innerContainer,
  .linksContainer {
    width: 100%;
  }
}
