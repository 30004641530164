.theme-activation-bg-image {
  background-image: url('../images/coffeeImg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

#leftCol {
  background: rgba(220, 215, 215, 0.9);
  color: rgb(59, 58, 58);
  padding: 1rem;
}

@media (max-width: 991px) {
  .theme-activation-bg-image {
    background-image: none !important;
  }
}

@media screen and (min-width: 1024px) {
  #leftCol {
    height: 100%;
    margin-top: 16rem;
  }
}
