.dropShadowWHN {
  -webkit-box-shadow: 0px 9px 8px -4px rgba(207, 206, 205, 0.7);
  -moz-box-shadow: 0px 9px 8px -4px rgba(207, 206, 205, 0.7);
  box-shadow: 0px 9px 8px -4px rgba(207, 206, 205, 0.7);
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  background-color: #002c5f;
}

@media (max-width: 991px) {
  .form-inline {
    margin-top: 0;
  }
}

@media (max-width: 1200px) {
  #basic-addon1 {
    display: none;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}
