@media (max-width: 768px) {
  .form-inline {
    margin-top: 1rem;
  }
}

.usernamebn {
  font-size: 1.3rem;
}

.bottom-navbar-dropdown {
  width: 20rem;

  background-color: rgba(255, 255, 255, 0.75);
}

.bottom-navbar-dropdown li {
  text-align: right;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0 0.75rem;
  background: white;
  margin-left: 1rem;
  margin-right: 1rem;
}

.bottom-navbar-dropdown .nav-item > .nav-link {
  display: flex;
  margin-right: 1.7rem;
}

.bottom-navbar-dropdown .nav-item > .dropdown-toggle {
  margin-right: 0 !important;
}

.bottom-navbar-dropdown .nav-item > .nav-link span {
  color: #002c5f !important;
  font-weight: 600;
  margin-left: auto;
}

.bottom-navbar-dropdown .nav-item > .nav-link span svg {
  margin-left: 0.5rem;
  color: rgba(0, 0, 0, 0.5);
}

.bottom-navbar-dropdown .dropdown-toggle::after {
  display: none;
}

.bottom-navbar-dropdown .dropdown-toggle .fa-angle-up {
  display: none;
}
.bottom-navbar-dropdown .dropdown-toggle .fa-angle-down {
  display: inline-block;
}

.bottom-navbar-dropdown .show .dropdown-toggle .fa-angle-up {
  display: inline-block;
}
.bottom-navbar-dropdown .show .dropdown-toggle .fa-angle-down {
  display: none;
}

.bottom-navbar-dropdown .dropdown-menu {
  text-align: right;
  border: none;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.bottom-navbar-dropdown .dropdown-menu a.dropdown-item {
  padding: 0.3rem 28px 0.3rem 0.3rem;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2);
  color: rgba(0, 0, 0, 0.5) !important;
  font-weight: 600;
}

.bottom-navbar-dropdown .dropdown-menu a.dropdown-item:last-child {
  border-bottom: none;
}
.bottom-navbar-dropdown .dropdown-menu a.dropdown-item:active {
  background-color: #92a5b2;
}
.theme-bottomnavbar {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.75);
  float: left;
  min-width: 10rem;

  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;

  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.theme-bottomnavbar-navbar-nav {
  margin-top: 1.1rem;
  margin-bottom: 1.1rem;
}
.theme-bottomnavbar-toggler {
  padding: 10px 0;
  margin-right: -4px;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  /* IE10+ CSS styles go here */
}
