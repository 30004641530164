.competition-input,
.competition-select {
  width: 100%;
  background-color: #ebebeb;
  border: none;
}

.competition-input.competition-form-element,
.competition-select.competition-form-element {
  padding: 5px;
  padding-left: 10px;
}

select {
  /* for Firefox */
  -moz-appearance: none;
  /* for Chrome */
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

.competition-select-container {
  padding-left: 0;
  padding-right: 0;
}

.competition-form-element {
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  padding: 1%;
  min-height: 32px;
  max-height: 32px;
}

.left-input {
  padding-left: 15px;
  padding-right: 7.5px;
}

.right-input {
  padding-left: 7.5px;
  padding-right: 15px;
}

.competition-select {
  color: #aaaaaa;
}

input {
  color: #222;
}

@media (max-width: 991px) {
  .left-input,
  .right-input {
    padding-left: 15px;
    padding-right: 15px;
  }
  #form-button {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
  }
  #terms-span {
    padding: 1rem;
  }
}
