.interests {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.interests .card {
  flex-basis: 31%;
  padding: 3px;
}

.interests .card .card-img-top {
  cursor: pointer;
}

.interests .card h5 {
  text-align: center;
  font-size: 0.8rem;
}

@media (max-width: 575px) {
  .interests .card {
    flex-basis: 50% !important;
    padding: 3px;
  }
}
