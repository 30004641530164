.theme-news-header-image {
  padding-bottom: 35%;
  /* This gives us a height of 322px by 920px */
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
  display: none;
  width: 100%;
  height: 100%;
  background-color: #e4e4e4;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  overflow: hidden;
}
.theme-news-header-video {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.theme-news-header-image.active {
  display: block;
}
.theme-news-thumbnail-container {
  display: block;
  flex-basis: 29%;
}
.theme-news-thumbnail {
  cursor: pointer;
  background-color: #e4e4e4;
  background-size: cover;
  background-position: center;
  overflow: hidden;
  padding-bottom: 100%;
}

.news-nav-container {
  width: 100%;
}

.news-nav-container > a {
  font-weight: bold;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 1rem 0;
  color: #212529;
  cursor: pointer;
  display: block;
}
.news-nav-container > a i {
  margin-left: 1rem;
}
.news-nav-container .nav-item {
  color: #212529;
}

@media (min-width: 768px) {
  .news-nav-container .nav-item {
    border-bottom: 1px dashed rgba(0, 0, 0, 0.1);
  }
}

.news-nav-container .nav-link {
  font-weight: bold;
  padding: 0;
  font-size: 0.95rem;
  cursor: pointer;
}

.news-nav-container .news-link-date {
  color: rgba(0, 0, 0, 0.5);
  font-size: 0.8rem;

  padding: 0 0 0.5rem 0rem;
}

.theme-news-social-circle-container {
  display: flex;
  justify-content: space-between;
}
.theme-news-social-circle {
  display: flex;
  border-radius: 50%;
  width: 2.1em;
  height: 2.1em;
  justify-content: center;
  align-items: center;
  -webkit-box-shadow: 0px 0px 0px 2px rgb(222, 226, 230);
  -moz-box-shadow: 0px 0px 0px 2px rgb(222, 226, 230);
  box-shadow: 0px 0px 0px 2px rgb(222, 226, 230);
}
.theme-news-snippet {
  color: black;
  padding: 1rem;
  font-style: italic;
  font-weight: lighter;
  font-size: 1.2rem;
}

.theme-news-body-text {
  font-size: 0.9rem;
  line-height: 1.7rem;
}
.theme-news-archive-title-image {
  height: 150px;
  background-position: center;
  background-size: cover;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
@media (max-width: 767px) {
  .theme-news-archive-mobile-link-border {
    border-right: 1px solid #dee2e6 !important;
    border-bottom: 1px solid #dee2e6 !important;
  }
}
.theme-news-archive-no-image {
  height: 5px;
  border-top-right-radius: 0.25rem !important;
}
.theme-news-archive-container-mobile {
}
